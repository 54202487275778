body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
textarea {
  font-family: 'Mulish';
}
input {
  font-family: 'Mulish';
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.MuiOutlinedInput-input {
  width: 100% !important;
  padding: 8px 14px !important;
  border: 1px solid #363740 !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  line-height: 20px !important;
  border-radius: 5px !important;
  text-transform: none !important;
  background-color: transparent !important;
}
.popupDatePickerWrapper .MuiFormControl-root {
  width: 100% !important;
  padding: 8px 14px !important;
  border: 1px solid #363740 !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  line-height: 20px !important;
  border-radius: 5px !important;
  text-transform: none !important;
  background-color: transparent !important;
}

select {
  border: 1px solid #363740 !important;
  padding: 7px 6px !important;
  font-size: 14px !important;
}

.css-3iigni-container .css-1insrsq-control {
  background-color: #ffffff;
}

.MuiAutocomplete-root .MuiAutocomplete-hasClearIcon .MuiAutocomplete-hasPopupIcon {
  width: 100% !important;
  padding: 8px 14px !important;
  border: 1px solid #363740 !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  line-height: 20px !important;
  border-radius: 5px !important;
  text-transform: none !important;
  background-color: transparent !important;
}
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
  padding-right: 0px !important;
  border: 1px solid #363740 !important;
  padding-bottom: 0px !important;
  border-radius: 5px !important;
}
.MuiInput-underline:before {
  border-bottom: none !important;
}
.MuiFormControl-root {
  width: 100% !important;
}
td {
  height: auto !important;
}
::-webkit-scrollbar {
  width: 0.5em;
  height: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  outline: 1px solid slategrey;
  border-radius: 17px;
  background-color: #bf2331;
}
.MuiGrid-item {
  padding: 5px !important;
}
.MuiOutlinedInput-root {
  margin-bottom: '10px !important';
}
.makeStyles-label-96 {
  margin-bottom: '5px !important';
}
.importantFontWeight {
  font-weight: 700 !important;
}
/* test */
