.auth-layout {
  display: flex;
  height: auto;
  min-height: 100vh;
}

.auth-layout .left-sec,
.auth-layout .right-sec {
  flex: 1;
}
.auth-layout .right-sec {
  align-items: center;
}

.auth-layout .left-sec {
  border-right: 8px solid #bf2331;
  background-size: cover !important;
  background-position: right !important;
}

.auth-layout .left-sec img {
  width: 100%;
}

.auth-layout .wrap {
  height: auto;
  min-height: 90vh;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.language-sec {
  text-align: right;
  margin-top: 10px;
  margin-right: 10px;
}

@media all and (max-width: 990px) {
  .auth-layout {
    display: block;
  }
  .auth-layout .left-sec {
    display: none;
  }
}
