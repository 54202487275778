.container-wrap {
  max-width: 45%;
  padding: 50px;
}

.auth-layout input {
  max-width: 94%;
}

.text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #2148c0;
  text-decoration: underline;
  float: right;
  cursor: pointer;
}
.error-code {
  color: red;
  font-size: 12px;
  margin-bottom: 10px;
  text-align: left;
  font-weight: 500;
}
button {
  margin: 15px 0 10px;
}
/* 
.text-wrap {
  width: 100%;
}

.text-wrap p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #bf2331;
  text-align: left;
  width: 100%;
}

.text-wrap p span {
  float: right;
  text-decoration: underline;
  cursor: pointer;
} */

.add-social-media .btn-wrap button:first-of-type {
  margin-bottom: -5px;
}

.link {
  float: left;
  margin-top: 0px;
  text-decoration: underline;
  color: #2148c0;
  font-weight: 500;
  cursor: pointer;
}
