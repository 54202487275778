.super-admin-area .admin-area {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.super-admin-area .admin-area h6 {
  margin: 0;
  margin-right: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #252733;
  text-transform: none;
}

/* .super-admin-area .admin-area img { */
/* border-radius: 50%; */
/* border: 3px solid #a4a6b3; */
/* } */

.super-admin-menu p {
  border-bottom: 1px solid #9fa2b4;
  text-align: center;
  justify-content: center;
  font-style: normal;
  line-height: 14px;
  letter-spacing: 0.4px;
  color: #000;
  padding: 10px 0;
  font-weight: 500;
  font-size: 10px;
  margin: 0;
  cursor: pointer;
}
.super-admin-menu p:hover {
  background-color: rgba(0, 0, 0, 0.04);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.super-admin-menu p:first-of-type {
  border-top: 1px solid #9fa2b4;
}
.super-admin-menu p:last-of-type {
  border-bottom: none;
}

.super-admin-menu {
  padding: 15px 10px 8px !important;
  width: 170px;
}
/* .super-admin-area {
    padding-right: 10px;
} */
.super-admin-menu img {
  width: 50px;
  margin-bottom: 10px;
}
