.dashboard-layout {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.dashboard-layout .left-sec {
  flex: 1;
  background-color: #363740;
}

.dashboard-layout .right-sec {
  flex: 4;
  padding: 20px 20px;
  background: #e5e5e566;
  width: 80%;
}

.bg {
  background-image: url('./sideBarImg.png');
  background-position: bottom;
  background-size: cover;
}

.mobile-menu {
  display: none;
}
@media all and (max-width: 960px) {
  .mobile-menu {
    display: block;
  }
  .dashboard-layout .left-sec,
  .desktop-menu {
    display: none;
  }
  .dashboard-layout .right-sec {
    margin-top: 50px;
  }
  .dashboard-layout .right-sec {
    width: 100%;
  }
  .makeStyles-drawerPaper-18 {
    background-image: url('./bg.svg');
  }
}
