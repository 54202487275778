.dashboard-card-sec {
  display: flex;
  justify-content: space-around;
  padding: 40px 20px;
  flex-wrap: wrap;
}

.participant-card-sec {
  display: flex;
  justify-content: space-around;
  padding: 40px 20px;
  flex-wrap: wrap;
}

@media all {
  .dashboard-card-sec {
    justify-content: space-around;
  }
}

@media all and (max-width: 1200px) {
  .dashboard-card-sec {
    justify-content: space-around;
  }
}

@media all and (max-width: 800px) {
  .dashboard-card-sec {
    justify-content: space-around;
  }
}

@media all and (max-width: 375px) {
  .dashboard-card-sec {
    justify-content: space-around;
  }
}
