.navbar li a {
  padding: 15px 20px;
  width: 100%;
}

.navbar li {
  padding: 0;
}

.navbar .icon {
  color: #fff;
}

/* .navbar li a {
    background-color: transparent !important;
} */

.navbar .MuiListItem-button {
  font-weight: bolder;
  font-size: 16px;
}

.navbar .MuiListItem-button:hover,
.navbar .MuiListItem-button:active,
.navbar .active {
  background-color: rgb(255 255 255 / 21%);
  opacity: 1 !important;
  border-left: 2px solid;
  color: #fff !important;
}

.navbar li:last-of-type {
  border-top: 1px solid #333;
  position: fixed;
  bottom: 0;
  width: 19%;
}

.img-cls {
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 9px;
  width: 84px;
  height: 80px;
  object-fit: contain;
}

.navbar li .img-text {
  color: #000000;
  margin-left: 10px;
}

.img-main-head {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.4px;
  color: #000000;
}

.img-name {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 41px;
  /* letter-spacing: 0.4px; */
  margin-top: -8px;
}

.img-sub {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.4px;
  margin-top: -5px;
}

.sub-nav li a {
  padding: 15px 20px;
  width: 100%;
}

.sub-nav li {
  padding: 0;
}

.sub-nav .icon {
  color: #000000;
}

/* .sub-nav li a {
    background-color: transparent !important;
} */

.sub-nav .MuiListItem-button {
  font-weight: 400;
  font-size: 16px;
}

.sub-nav .MuiListItem-button:hover,
.sub-nav .MuiListItem-button:active,
.sub-nav .active {
  background-color: #9fa2b426;
  border-left: 2px solid;
  color: #ecedf3;
}

.sub-nav li:last-of-type {
  border-top: 1px solid #333;
  position: fixed;
  bottom: 0;
  width: 19%;
}

.paper {
  width: 250px;
  height: auto;
  background: #363740;
}

.sub-nav {
  width: 100% !important;
}

.version {
  width: 19.5%;
  position: fixed;
  bottom: 0;
  pointer-events: all;
  background-color: #646363;
  font-size: small;
}

.navbar li:last-of-type {
  margin-bottom: 20px;
}

.img-name {
  text-overflow: ellipsis;
  overflow: hidden;
}

/* .version .center-text{
    display: flex;
    justify-content: center;
} */
.userImage {
  display: flex;
}

@media (max-width: 500px) {
  .userImage {
    display: flex;
    flex-direction: column;
  }

  .navbar li:last-of-type {
    width: 57%;
  }
  .navbar li .img-text {
    margin-left: 0;
  }
}
