.MuiInput-underline:after {
  border-bottom: none !important;
}
.MuiFormControl-root {
  height: 36px;
  justify-content: center;
}
.imgC p {
  margin-left: -300px;
}
.grid-item-image-custom-class,
.grid-item-image-custom-class > div {
  height: 100% !important;
}
