body {
  font-family: 'Mulish', sans-serif !important;
}

input:focus-visible {
  outline-color: #bf2331 !important;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}
/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}
.App {
  text-align: center;
}
select {
  font-size: 13px;
  width: 100%;
  border: 1px solid #d4d5de;
  border-radius: 4px;
  line-height: 1;
  outline: 0;
  padding: 10px 10px;
  margin-bottom: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.navbar .MuiPaper-elevation1 {
  box-shadow: none !important;
}

.Toastify__toast-body {
  font-family: 'Mulish' !important;
  color: #000;
  font-size: 14px;
  line-height: 1.6;
}

@media all and (max-width: 650px) {
  .MuiGrid-grid-xs-4 {
    flex-grow: 0;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}

@media all and (max-width: 767px) {
  .MuiTableCell-stickyHeader {
    position: initial !important;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.MuiInput-underline:after {
  border-bottom: none !important;
}
.MuiFormControl-root {
  height: 36px;
  justify-content: center;
}

/* 

::-webkit-scrollbar {
  width: 5px;
}


::-webkit-scrollbar-track {
  -webkit-box-shadow: auto; 
  -webkit-border-radius: auto;
  border-radius: auto;
  background:#F5F5F5;
}


::-webkit-scrollbar-thumb {
  -webkit-border-radius:auto;
  border-radius:auto;
  background:#2148C0;
  -webkit-box-shadow:auto; 
}
::-webkit-scrollbar-thumb:window-inactive {
  background: auto; 
} */

.Toastify__toast-body {
  white-space: pre-line;
}

/* div:has(> div.grid-item-dropdown-custom-class) {
  z-index: 9999;
} */
.MuiTabs-flexContainer {
  justify-content: center;
}
.MuiOutlinedInput-adornedStart{
  cursor: pointer !important;
}
.MuiOutlinedInput-input {
  border: none !important;
}