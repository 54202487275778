.header-area .wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header-area .wrapper .title {
  flex: 1;
}

.header-area .wrapper .title h3 {
  text-align: left;
  margin: 0;
}

.header-area .wrapper .right {
  flex: 3;
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.header-area {
  padding-bottom: 10px;
  border-bottom: 1px solid #dfe0eb;
}
.select {
  border: none !important;
  outline: none !important;
  width: 100px;
  background-color: #f2f2f2;
  color: #bf2331;
  margin-bottom: 10px;
}

.select option {
  border: none !important;
  outline: none !important;
}

.right select {
  width: 100px;
  float: left;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  background: url('arrow_red.svg');
  background-repeat: no-repeat;
  background-size: 16px 17px;
  background-position: 75px 7px;
  margin-top: 10px;
  font-size: 15px !important;
  color: #bf2331;
  font-weight: 400 !important;
  text-decoration: underline !important;
}

@media all and (max-width: 650px) {
  .right {
    justify-content: flex-start !important;
    flex-direction: column-reverse;
    align-content: center;
    align-items: end !important;
    flex-wrap: wrap;
    max-width: 160px;
  }
  /* .title{

    } */
}
