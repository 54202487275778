.MuiInput-underline:after {
  border-bottom: none !important;
}
.MuiFormControl-root {
  height: 36px;
  justify-content: center;
}
.grid-item-image-custom-class,
.grid-item-image-custom-class > div {
  height: 100% !important;
}
.grid-item-textarea-custom-class,
.grid-item-textarea-custom-class > div {
  height: 100% !important;
}
.popupDatePickerWrapper .MuiFormHelperText-root.Mui-error {
  position: absolute;
  bottom: -21px;
}
