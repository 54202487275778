.MuiInput-underline:after {
  border-bottom: none !important;
}

.MuiFormControl-root {
  height: 36px;
  justify-content: center;
}

.grid-item-image-custom-class,
.grid-item-image-custom-class > div {
  height: 100% !important;
}

.grid-item-table-custom-class {
  height: 100%;
}

.chip,
.singleChip {
  white-space: normal !important;
}

.chip {
  word-break: break-word;
}

/* .MuiTableCell-root {
  
  text-align: center !important; 

}  */
